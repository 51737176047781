const styles = (theme) => {
  const { baseUnit, palette, mixins } = theme;

  return {
    root: {
      alignItems: 'center',
      display: 'flex',
      marginBottom: baseUnit,
      maxHeight: 14,
      minWidth: 0,
    },
    large: {
      maxHeight: 'none',
    },
    text: {
      ...mixins.truncate,
    },
    disabled: {
      color: palette.text.disabled,
    },
    requiredAsterisk: {
      color: palette.error.main,
    },
    icon: {
      height: baseUnit * 4,
      width: baseUnit * 4,
      color: palette.icons.gray,
    },
  };
};

export default styles;
